import { default as __indexIUfKi05x4WMeta } from "/vercel/path0/layers/tairo/pages/__index.vue?macro=true";
import { default as _91_91slug_93_93JEdq5zRQnGMeta } from "/vercel/path0/.app/pages/[[slug]].vue?macro=true";
import { default as indexMw2JLpXFjBMeta } from "/vercel/path0/meedoen/pages/[balie]/index.vue?macro=true";
import { default as _91_91slug_93_93HJ27qLc1LMMeta } from "/vercel/path0/meedoen/pages/[balie]/lokaal/[[slug]].vue?macro=true";
import { default as _91slug_93Fe3pdb9taHMeta } from "/vercel/path0/meedoen/pages/[balie]/nieuwkomer/[slug].vue?macro=true";
import { default as _91id_93hrfdITz4fBMeta } from "/vercel/path0/meedoen/pages/[balie]/nieuwkomer/activiteiten/[id].vue?macro=true";
import { default as indexFmNNiZRxOzMeta } from "/vercel/path0/meedoen/pages/[balie]/nieuwkomer/activiteiten/index.vue?macro=true";
import { default as indexMLALKqRq2EMeta } from "/vercel/path0/meedoen/pages/[balie]/nieuwkomer/index.vue?macro=true";
import { default as activiteitenT4z8SCV5C2Meta } from "/vercel/path0/volunteerly/pages/activiteiten.vue?macro=true";
import { default as _91_91slug_93_93HcDVJyG1UQMeta } from "/vercel/path0/meedoen/pages/algemeen/[[slug]].vue?macro=true";
import { default as index_4528SAiEe01y8Meta } from "/vercel/path0/.app/pages/analytics/index-2.vue?macro=true";
import { default as indexTS7YDSsDxsMeta } from "/vercel/path0/.app/pages/analytics/index.vue?macro=true";
import { default as indexkE9U0V9u5FMeta } from "/vercel/path0/volunteerly/pages/applications/[id]/index.vue?macro=true";
import { default as indexxGxwkYrzWgMeta } from "/vercel/path0/volunteerly/pages/applications/index.vue?macro=true";
import { default as login_451PzktOBWuMrMeta } from "/vercel/path0/.app/pages/auth/login-1.vue?macro=true";
import { default as recoverDHSNCySTpTMeta } from "/vercel/path0/.app/pages/auth/recover.vue?macro=true";
import { default as reset389BRdnuMwMeta } from "/vercel/path0/.app/pages/auth/reset.vue?macro=true";
import { default as token5YDpd8Z7VVMeta } from "/vercel/path0/.app/pages/auth/token.vue?macro=true";
import { default as indexF8DSiko5OYMeta } from "/vercel/path0/volunteerly/pages/confirm/index.vue?macro=true";
import { default as indexmGKQ2lMWSUMeta } from "/vercel/path0/volunteerly/pages/content/index.vue?macro=true";
import { default as menu_45itemsfeMD9jViX6Meta } from "/vercel/path0/volunteerly/pages/content/menu-items.vue?macro=true";
import { default as contentTavsgksC19Meta } from "/vercel/path0/.app/pages/content.vue?macro=true";
import { default as index56yHaQOcEwMeta } from "/vercel/path0/volunteerly/pages/conversations/index.vue?macro=true";
import { default as indexVHFQNfOucFMeta } from "/vercel/path0/volunteerly/pages/dashboard/index.vue?macro=true";
import { default as een_45op_45een_45hulpIFjwrhyJ5GMeta } from "/vercel/path0/volunteerly/pages/een-op-een-hulp.vue?macro=true";
import { default as evenementen6HWTjfxjUJMeta } from "/vercel/path0/volunteerly/pages/evenementen.vue?macro=true";
import { default as gelderse_45handen_45coming_45soonBilOpwYOOFMeta } from "/vercel/path0/volunteerly/pages/gelderse-handen-coming-soon.vue?macro=true";
import { default as gelderse_45handenrslWXfSF6wMeta } from "/vercel/path0/volunteerly/pages/gelderse-handen.vue?macro=true";
import { default as indexknTsYj9U2tMeta } from "/vercel/path0/meedoen/pages/index.vue?macro=true";
import { default as kies_45balieKhdNXyUbKZMeta } from "/vercel/path0/meedoen/pages/kies-balie.vue?macro=true";
import { default as kies_45taalXDodSJbI1zMeta } from "/vercel/path0/meedoen/pages/kies-taal.vue?macro=true";
import { default as beheerderstZSDvkBCJoMeta } from "/vercel/path0/meedoen/pages/nov/beheerders.vue?macro=true";
import { default as editFITNY3pYJwMeta } from "/vercel/path0/volunteerly/pages/organizations/[id]/edit.vue?macro=true";
import { default as index77NsS3hu5uMeta } from "/vercel/path0/volunteerly/pages/organizations/[id]/index.vue?macro=true";
import { default as movejsuXe8XfmTMeta } from "/vercel/path0/volunteerly/pages/organizations/[id]/move.vue?macro=true";
import { default as addP52rWBDGPPMeta } from "/vercel/path0/volunteerly/pages/organizations/add.vue?macro=true";
import { default as approvem8vSRTEV9BMeta } from "/vercel/path0/volunteerly/pages/organizations/approve.vue?macro=true";
import { default as indexlu3wSGqaurMeta } from "/vercel/path0/volunteerly/pages/organizations/index.vue?macro=true";
import { default as indexusZAFVDdNBMeta } from "/vercel/path0/volunteerly/pages/organizations/signup/index.vue?macro=true";
import { default as successRrbkml8PUiMeta } from "/vercel/path0/volunteerly/pages/organizations/signup/success.vue?macro=true";
import { default as partners4GDv9bgdLAMeta } from "/vercel/path0/volunteerly/pages/partners.vue?macro=true";
import { default as indexB7MJzNEvvpMeta } from "/vercel/path0/volunteerly/pages/profiel/index.vue?macro=true";
import { default as verwijderdrySgyFtWiWMeta } from "/vercel/path0/volunteerly/pages/profiel/verwijderd.vue?macro=true";
import { default as indexn3RRajCth4Meta } from "/vercel/path0/volunteerly/pages/profile-edit/index.vue?macro=true";
import { default as settingsE9CbXIxDbcMeta } from "/vercel/path0/volunteerly/pages/profile-edit/settings.vue?macro=true";
import { default as profile_45edit0oMFymvf4iMeta } from "/vercel/path0/.app/pages/profile-edit.vue?macro=true";
import { default as search7Y1cKse8QuMeta } from "/vercel/path0/volunteerly/pages/public/search.vue?macro=true";
import { default as contact_45personJVTxno0VUMMeta } from "/vercel/path0/volunteerly/pages/settings/contact-person.vue?macro=true";
import { default as design9iYFXq8tzrMeta } from "/vercel/path0/volunteerly/pages/settings/design.vue?macro=true";
import { default as footerdrmFjXTUSRMeta } from "/vercel/path0/volunteerly/pages/settings/footer.vue?macro=true";
import { default as indexjZuWh0s663Meta } from "/vercel/path0/volunteerly/pages/settings/index.vue?macro=true";
import { default as settingszwBuDWBhVEMeta } from "/vercel/path0/volunteerly/pages/settings.vue?macro=true";
import { default as indexsTiQiaYGmpMeta } from "/vercel/path0/volunteerly/pages/signup/index.vue?macro=true";
import { default as successOwaq1Q43PtMeta } from "/vercel/path0/volunteerly/pages/signup/success.vue?macro=true";
import { default as switchn38JyRw5LxMeta } from "/vercel/path0/volunteerly/pages/switch.vue?macro=true";
import { default as authkaSMnQT4WcMeta } from "/vercel/path0/.app/pages/test/auth.vue?macro=true";
import { default as dupeI47ZtOXqSqMeta } from "/vercel/path0/.app/pages/test/dupe.vue?macro=true";
import { default as formkm7kcPhdf0Meta } from "/vercel/path0/.app/pages/test/form.vue?macro=true";
import { default as server_45errorzkZTzpIMvfMeta } from "/vercel/path0/.app/pages/test/server-error.vue?macro=true";
import { default as step_45form_452zOUvlFOLgwMeta } from "/vercel/path0/.app/pages/test/step-form-2.vue?macro=true";
import { default as step_45formbVuEsOyEdOMeta } from "/vercel/path0/.app/pages/test/step-form.vue?macro=true";
import { default as toasterdGo68mWjbTMeta } from "/vercel/path0/.app/pages/test/toaster.vue?macro=true";
import { default as user_45storeNZaKEdPvEJMeta } from "/vercel/path0/.app/pages/test/user-store.vue?macro=true";
import { default as usermbs7HBNeeAMeta } from "/vercel/path0/.app/pages/test/user.vue?macro=true";
import { default as uitloggenJpGYx4npziMeta } from "/vercel/path0/.app/pages/uitloggen.vue?macro=true";
import { default as add_role1rGOgsOd7sMeta } from "/vercel/path0/.app/pages/users/[id]/add_role.vue?macro=true";
import { default as edit2D4CWW5dzWMeta } from "/vercel/path0/.app/pages/users/[id]/edit.vue?macro=true";
import { default as indexfosjwbxWtaMeta } from "/vercel/path0/.app/pages/users/[id]/index.vue?macro=true";
import { default as adds9wtR2FJuZMeta } from "/vercel/path0/.app/pages/users/add.vue?macro=true";
import { default as indexdlFaUjo5BDMeta } from "/vercel/path0/.app/pages/users/index.vue?macro=true";
import { default as editLjOfR8W4PVMeta } from "/vercel/path0/volunteerly/pages/vacancies/[id]/edit.vue?macro=true";
import { default as indexVRhjNSCG7qMeta } from "/vercel/path0/meedoen/pages/vacancies/[id]/index.vue?macro=true";
import { default as addrZKEE65rncMeta } from "/vercel/path0/volunteerly/pages/vacancies/add.vue?macro=true";
import { default as indexlBmD0PXZ6hMeta } from "/vercel/path0/volunteerly/pages/vacancies/index.vue?macro=true";
import { default as indexF6e2XAjFIcMeta } from "/vercel/path0/volunteerly/pages/vacancies/search/index.vue?macro=true";
import { default as indexfPOpclTk8DMeta } from "/vercel/path0/volunteerly/pages/verschilmakers/index.vue?macro=true";
import { default as changelog64EQ8JmpEUMeta } from "/vercel/path0/volunteerly/pages/volunteerly/changelog.vue?macro=true";
import { default as workshopsZ0xK4fIBTGMeta } from "/vercel/path0/volunteerly/pages/workshops.vue?macro=true";
import { default as wrong_45deskSKshfXIVEMMeta } from "/vercel/path0/meedoen/pages/wrong-desk.vue?macro=true";
export default [
  {
    name: __indexIUfKi05x4WMeta?.name ?? "__index",
    path: __indexIUfKi05x4WMeta?.path ?? "/__index",
    meta: __indexIUfKi05x4WMeta || {},
    alias: __indexIUfKi05x4WMeta?.alias || [],
    redirect: __indexIUfKi05x4WMeta?.redirect,
    component: () => import("/vercel/path0/layers/tairo/pages/__index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93JEdq5zRQnGMeta?.name ?? "slug",
    path: _91_91slug_93_93JEdq5zRQnGMeta?.path ?? "/:slug?",
    meta: _91_91slug_93_93JEdq5zRQnGMeta || {},
    alias: _91_91slug_93_93JEdq5zRQnGMeta?.alias || [],
    redirect: _91_91slug_93_93JEdq5zRQnGMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexMw2JLpXFjBMeta?.name ?? "balie",
    path: indexMw2JLpXFjBMeta?.path ?? "/:balie()",
    meta: indexMw2JLpXFjBMeta || {},
    alias: indexMw2JLpXFjBMeta?.alias || [],
    redirect: indexMw2JLpXFjBMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/[balie]/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93HJ27qLc1LMMeta?.name ?? "balie-lokaal-slug",
    path: _91_91slug_93_93HJ27qLc1LMMeta?.path ?? "/:balie()/lokaal/:slug?",
    meta: _91_91slug_93_93HJ27qLc1LMMeta || {},
    alias: _91_91slug_93_93HJ27qLc1LMMeta?.alias || [],
    redirect: _91_91slug_93_93HJ27qLc1LMMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/[balie]/lokaal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Fe3pdb9taHMeta?.name ?? "balie-nieuwkomer-slug",
    path: _91slug_93Fe3pdb9taHMeta?.path ?? "/:balie()/nieuwkomer/:slug()",
    meta: _91slug_93Fe3pdb9taHMeta || {},
    alias: _91slug_93Fe3pdb9taHMeta?.alias || [],
    redirect: _91slug_93Fe3pdb9taHMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/[balie]/nieuwkomer/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93hrfdITz4fBMeta?.name ?? "balie-nieuwkomer-activiteiten-id",
    path: _91id_93hrfdITz4fBMeta?.path ?? "/:balie()/nieuwkomer/activiteiten/:id()",
    meta: _91id_93hrfdITz4fBMeta || {},
    alias: _91id_93hrfdITz4fBMeta?.alias || [],
    redirect: _91id_93hrfdITz4fBMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/[balie]/nieuwkomer/activiteiten/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFmNNiZRxOzMeta?.name ?? "balie-nieuwkomer-activiteiten",
    path: indexFmNNiZRxOzMeta?.path ?? "/:balie()/nieuwkomer/activiteiten",
    meta: indexFmNNiZRxOzMeta || {},
    alias: indexFmNNiZRxOzMeta?.alias || [],
    redirect: indexFmNNiZRxOzMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/[balie]/nieuwkomer/activiteiten/index.vue").then(m => m.default || m)
  },
  {
    name: indexMLALKqRq2EMeta?.name ?? "balie-nieuwkomer",
    path: indexMLALKqRq2EMeta?.path ?? "/:balie()/nieuwkomer",
    meta: indexMLALKqRq2EMeta || {},
    alias: indexMLALKqRq2EMeta?.alias || [],
    redirect: indexMLALKqRq2EMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/[balie]/nieuwkomer/index.vue").then(m => m.default || m)
  },
  {
    name: activiteitenT4z8SCV5C2Meta?.name ?? "activiteiten",
    path: activiteitenT4z8SCV5C2Meta?.path ?? "/activiteiten",
    meta: activiteitenT4z8SCV5C2Meta || {},
    alias: activiteitenT4z8SCV5C2Meta?.alias || [],
    redirect: activiteitenT4z8SCV5C2Meta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/activiteiten.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93HcDVJyG1UQMeta?.name ?? "algemeen-slug",
    path: _91_91slug_93_93HcDVJyG1UQMeta?.path ?? "/algemeen/:slug?",
    meta: _91_91slug_93_93HcDVJyG1UQMeta || {},
    alias: _91_91slug_93_93HcDVJyG1UQMeta?.alias || [],
    redirect: _91_91slug_93_93HcDVJyG1UQMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/algemeen/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: index_4528SAiEe01y8Meta?.name ?? "analytics-index-2",
    path: index_4528SAiEe01y8Meta?.path ?? "/analytics/index-2",
    meta: index_4528SAiEe01y8Meta || {},
    alias: index_4528SAiEe01y8Meta?.alias || [],
    redirect: index_4528SAiEe01y8Meta?.redirect,
    component: () => import("/vercel/path0/.app/pages/analytics/index-2.vue").then(m => m.default || m)
  },
  {
    name: indexTS7YDSsDxsMeta?.name ?? "analytics",
    path: indexTS7YDSsDxsMeta?.path ?? "/analytics",
    meta: indexTS7YDSsDxsMeta || {},
    alias: indexTS7YDSsDxsMeta?.alias || [],
    redirect: indexTS7YDSsDxsMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: indexkE9U0V9u5FMeta?.name ?? "applications-id",
    path: indexkE9U0V9u5FMeta?.path ?? "/applications/:id()",
    meta: indexkE9U0V9u5FMeta || {},
    alias: indexkE9U0V9u5FMeta?.alias || [],
    redirect: indexkE9U0V9u5FMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/applications/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxGxwkYrzWgMeta?.name ?? "applications",
    path: indexxGxwkYrzWgMeta?.path ?? "/applications",
    meta: indexxGxwkYrzWgMeta || {},
    alias: indexxGxwkYrzWgMeta?.alias || [],
    redirect: indexxGxwkYrzWgMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/applications/index.vue").then(m => m.default || m)
  },
  {
    name: login_451PzktOBWuMrMeta?.name ?? "auth-login-1",
    path: login_451PzktOBWuMrMeta?.path ?? "/auth/login-1",
    meta: login_451PzktOBWuMrMeta || {},
    alias: login_451PzktOBWuMrMeta?.alias || [],
    redirect: login_451PzktOBWuMrMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/auth/login-1.vue").then(m => m.default || m)
  },
  {
    name: recoverDHSNCySTpTMeta?.name ?? "auth-recover",
    path: recoverDHSNCySTpTMeta?.path ?? "/auth/recover",
    meta: recoverDHSNCySTpTMeta || {},
    alias: recoverDHSNCySTpTMeta?.alias || [],
    redirect: recoverDHSNCySTpTMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: reset389BRdnuMwMeta?.name ?? "auth-reset",
    path: reset389BRdnuMwMeta?.path ?? "/auth/reset",
    meta: reset389BRdnuMwMeta || {},
    alias: reset389BRdnuMwMeta?.alias || [],
    redirect: reset389BRdnuMwMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: token5YDpd8Z7VVMeta?.name ?? "auth-token",
    path: token5YDpd8Z7VVMeta?.path ?? "/auth/token",
    meta: token5YDpd8Z7VVMeta || {},
    alias: token5YDpd8Z7VVMeta?.alias || [],
    redirect: token5YDpd8Z7VVMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/auth/token.vue").then(m => m.default || m)
  },
  {
    name: indexF8DSiko5OYMeta?.name ?? "confirm",
    path: indexF8DSiko5OYMeta?.path ?? "/confirm",
    meta: indexF8DSiko5OYMeta || {},
    alias: indexF8DSiko5OYMeta?.alias || [],
    redirect: indexF8DSiko5OYMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: contentTavsgksC19Meta?.name ?? undefined,
    path: contentTavsgksC19Meta?.path ?? "/content",
    meta: contentTavsgksC19Meta || {},
    alias: contentTavsgksC19Meta?.alias || [],
    redirect: contentTavsgksC19Meta?.redirect,
    component: () => import("/vercel/path0/.app/pages/content.vue").then(m => m.default || m),
    children: [
  {
    name: indexmGKQ2lMWSUMeta?.name ?? "content",
    path: indexmGKQ2lMWSUMeta?.path ?? "",
    meta: indexmGKQ2lMWSUMeta || {},
    alias: indexmGKQ2lMWSUMeta?.alias || [],
    redirect: indexmGKQ2lMWSUMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: menu_45itemsfeMD9jViX6Meta?.name ?? "content-menu-items",
    path: menu_45itemsfeMD9jViX6Meta?.path ?? "menu-items",
    meta: menu_45itemsfeMD9jViX6Meta || {},
    alias: menu_45itemsfeMD9jViX6Meta?.alias || [],
    redirect: menu_45itemsfeMD9jViX6Meta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/content/menu-items.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index56yHaQOcEwMeta?.name ?? "conversations",
    path: index56yHaQOcEwMeta?.path ?? "/conversations",
    meta: index56yHaQOcEwMeta || {},
    alias: index56yHaQOcEwMeta?.alias || [],
    redirect: index56yHaQOcEwMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/conversations/index.vue").then(m => m.default || m)
  },
  {
    name: indexVHFQNfOucFMeta?.name ?? "dashboard",
    path: indexVHFQNfOucFMeta?.path ?? "/dashboard",
    meta: indexVHFQNfOucFMeta || {},
    alias: indexVHFQNfOucFMeta?.alias || [],
    redirect: indexVHFQNfOucFMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: een_45op_45een_45hulpIFjwrhyJ5GMeta?.name ?? "een-op-een-hulp",
    path: een_45op_45een_45hulpIFjwrhyJ5GMeta?.path ?? "/een-op-een-hulp",
    meta: een_45op_45een_45hulpIFjwrhyJ5GMeta || {},
    alias: een_45op_45een_45hulpIFjwrhyJ5GMeta?.alias || [],
    redirect: een_45op_45een_45hulpIFjwrhyJ5GMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/een-op-een-hulp.vue").then(m => m.default || m)
  },
  {
    name: evenementen6HWTjfxjUJMeta?.name ?? "evenementen",
    path: evenementen6HWTjfxjUJMeta?.path ?? "/evenementen",
    meta: evenementen6HWTjfxjUJMeta || {},
    alias: evenementen6HWTjfxjUJMeta?.alias || [],
    redirect: evenementen6HWTjfxjUJMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/evenementen.vue").then(m => m.default || m)
  },
  {
    name: gelderse_45handen_45coming_45soonBilOpwYOOFMeta?.name ?? "gelderse-handen-coming-soon",
    path: gelderse_45handen_45coming_45soonBilOpwYOOFMeta?.path ?? "/gelderse-handen-coming-soon",
    meta: gelderse_45handen_45coming_45soonBilOpwYOOFMeta || {},
    alias: gelderse_45handen_45coming_45soonBilOpwYOOFMeta?.alias || [],
    redirect: gelderse_45handen_45coming_45soonBilOpwYOOFMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/gelderse-handen-coming-soon.vue").then(m => m.default || m)
  },
  {
    name: gelderse_45handenrslWXfSF6wMeta?.name ?? "gelderse-handen",
    path: gelderse_45handenrslWXfSF6wMeta?.path ?? "/gelderse-handen",
    meta: gelderse_45handenrslWXfSF6wMeta || {},
    alias: gelderse_45handenrslWXfSF6wMeta?.alias || [],
    redirect: gelderse_45handenrslWXfSF6wMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/gelderse-handen.vue").then(m => m.default || m)
  },
  {
    name: indexknTsYj9U2tMeta?.name ?? "index",
    path: indexknTsYj9U2tMeta?.path ?? "/",
    meta: indexknTsYj9U2tMeta || {},
    alias: indexknTsYj9U2tMeta?.alias || [],
    redirect: indexknTsYj9U2tMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kies_45balieKhdNXyUbKZMeta?.name ?? "kies-balie",
    path: kies_45balieKhdNXyUbKZMeta?.path ?? "/kies-balie",
    meta: kies_45balieKhdNXyUbKZMeta || {},
    alias: kies_45balieKhdNXyUbKZMeta?.alias || [],
    redirect: kies_45balieKhdNXyUbKZMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/kies-balie.vue").then(m => m.default || m)
  },
  {
    name: kies_45taalXDodSJbI1zMeta?.name ?? "kies-taal",
    path: kies_45taalXDodSJbI1zMeta?.path ?? "/kies-taal",
    meta: kies_45taalXDodSJbI1zMeta || {},
    alias: kies_45taalXDodSJbI1zMeta?.alias || [],
    redirect: kies_45taalXDodSJbI1zMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/kies-taal.vue").then(m => m.default || m)
  },
  {
    name: beheerderstZSDvkBCJoMeta?.name ?? "nov-beheerders",
    path: beheerderstZSDvkBCJoMeta?.path ?? "/nov/beheerders",
    meta: beheerderstZSDvkBCJoMeta || {},
    alias: beheerderstZSDvkBCJoMeta?.alias || [],
    redirect: beheerderstZSDvkBCJoMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/nov/beheerders.vue").then(m => m.default || m)
  },
  {
    name: editFITNY3pYJwMeta?.name ?? "organizations-id-edit",
    path: editFITNY3pYJwMeta?.path ?? "/organizations/:id()/edit",
    meta: editFITNY3pYJwMeta || {},
    alias: editFITNY3pYJwMeta?.alias || [],
    redirect: editFITNY3pYJwMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/organizations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index77NsS3hu5uMeta?.name ?? "organizations-id",
    path: index77NsS3hu5uMeta?.path ?? "/organizations/:id()",
    meta: index77NsS3hu5uMeta || {},
    alias: index77NsS3hu5uMeta?.alias || [],
    redirect: index77NsS3hu5uMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/organizations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: movejsuXe8XfmTMeta?.name ?? "organizations-id-move",
    path: movejsuXe8XfmTMeta?.path ?? "/organizations/:id()/move",
    meta: movejsuXe8XfmTMeta || {},
    alias: movejsuXe8XfmTMeta?.alias || [],
    redirect: movejsuXe8XfmTMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/organizations/[id]/move.vue").then(m => m.default || m)
  },
  {
    name: addP52rWBDGPPMeta?.name ?? "organizations-add",
    path: addP52rWBDGPPMeta?.path ?? "/organizations/add",
    meta: addP52rWBDGPPMeta || {},
    alias: addP52rWBDGPPMeta?.alias || [],
    redirect: addP52rWBDGPPMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/organizations/add.vue").then(m => m.default || m)
  },
  {
    name: approvem8vSRTEV9BMeta?.name ?? "organizations-approve",
    path: approvem8vSRTEV9BMeta?.path ?? "/organizations/approve",
    meta: approvem8vSRTEV9BMeta || {},
    alias: approvem8vSRTEV9BMeta?.alias || [],
    redirect: approvem8vSRTEV9BMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/organizations/approve.vue").then(m => m.default || m)
  },
  {
    name: indexlu3wSGqaurMeta?.name ?? "organizations",
    path: indexlu3wSGqaurMeta?.path ?? "/organizations",
    meta: indexlu3wSGqaurMeta || {},
    alias: indexlu3wSGqaurMeta?.alias || [],
    redirect: indexlu3wSGqaurMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexusZAFVDdNBMeta?.name ?? "organizations-signup",
    path: indexusZAFVDdNBMeta?.path ?? "/organizations/signup",
    meta: indexusZAFVDdNBMeta || {},
    alias: indexusZAFVDdNBMeta?.alias || [],
    redirect: indexusZAFVDdNBMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/organizations/signup/index.vue").then(m => m.default || m)
  },
  {
    name: successRrbkml8PUiMeta?.name ?? "organizations-signup-success",
    path: successRrbkml8PUiMeta?.path ?? "/organizations/signup/success",
    meta: successRrbkml8PUiMeta || {},
    alias: successRrbkml8PUiMeta?.alias || [],
    redirect: successRrbkml8PUiMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/organizations/signup/success.vue").then(m => m.default || m)
  },
  {
    name: partners4GDv9bgdLAMeta?.name ?? "partners",
    path: partners4GDv9bgdLAMeta?.path ?? "/partners",
    meta: partners4GDv9bgdLAMeta || {},
    alias: partners4GDv9bgdLAMeta?.alias || [],
    redirect: partners4GDv9bgdLAMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: indexB7MJzNEvvpMeta?.name ?? "profiel",
    path: indexB7MJzNEvvpMeta?.path ?? "/profiel",
    meta: indexB7MJzNEvvpMeta || {},
    alias: indexB7MJzNEvvpMeta?.alias || [],
    redirect: indexB7MJzNEvvpMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/profiel/index.vue").then(m => m.default || m)
  },
  {
    name: verwijderdrySgyFtWiWMeta?.name ?? "profiel-verwijderd",
    path: verwijderdrySgyFtWiWMeta?.path ?? "/profiel/verwijderd",
    meta: verwijderdrySgyFtWiWMeta || {},
    alias: verwijderdrySgyFtWiWMeta?.alias || [],
    redirect: verwijderdrySgyFtWiWMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/profiel/verwijderd.vue").then(m => m.default || m)
  },
  {
    name: profile_45edit0oMFymvf4iMeta?.name ?? undefined,
    path: profile_45edit0oMFymvf4iMeta?.path ?? "/profile-edit",
    meta: profile_45edit0oMFymvf4iMeta || {},
    alias: profile_45edit0oMFymvf4iMeta?.alias || [],
    redirect: profile_45edit0oMFymvf4iMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/profile-edit.vue").then(m => m.default || m),
    children: [
  {
    name: indexn3RRajCth4Meta?.name ?? "profile-edit",
    path: indexn3RRajCth4Meta?.path ?? "",
    meta: indexn3RRajCth4Meta || {},
    alias: indexn3RRajCth4Meta?.alias || [],
    redirect: indexn3RRajCth4Meta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/profile-edit/index.vue").then(m => m.default || m)
  },
  {
    name: settingsE9CbXIxDbcMeta?.name ?? "profile-edit-settings",
    path: settingsE9CbXIxDbcMeta?.path ?? "settings",
    meta: settingsE9CbXIxDbcMeta || {},
    alias: settingsE9CbXIxDbcMeta?.alias || [],
    redirect: settingsE9CbXIxDbcMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/profile-edit/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: search7Y1cKse8QuMeta?.name ?? "public-search",
    path: search7Y1cKse8QuMeta?.path ?? "/public/search",
    meta: search7Y1cKse8QuMeta || {},
    alias: search7Y1cKse8QuMeta?.alias || [],
    redirect: search7Y1cKse8QuMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/public/search.vue").then(m => m.default || m)
  },
  {
    name: settingszwBuDWBhVEMeta?.name ?? undefined,
    path: settingszwBuDWBhVEMeta?.path ?? "/settings",
    meta: settingszwBuDWBhVEMeta || {},
    alias: settingszwBuDWBhVEMeta?.alias || [],
    redirect: settingszwBuDWBhVEMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: contact_45personJVTxno0VUMMeta?.name ?? "settings-contact-person",
    path: contact_45personJVTxno0VUMMeta?.path ?? "contact-person",
    meta: contact_45personJVTxno0VUMMeta || {},
    alias: contact_45personJVTxno0VUMMeta?.alias || [],
    redirect: contact_45personJVTxno0VUMMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/settings/contact-person.vue").then(m => m.default || m)
  },
  {
    name: design9iYFXq8tzrMeta?.name ?? "settings-design",
    path: design9iYFXq8tzrMeta?.path ?? "design",
    meta: design9iYFXq8tzrMeta || {},
    alias: design9iYFXq8tzrMeta?.alias || [],
    redirect: design9iYFXq8tzrMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/settings/design.vue").then(m => m.default || m)
  },
  {
    name: footerdrmFjXTUSRMeta?.name ?? "settings-footer",
    path: footerdrmFjXTUSRMeta?.path ?? "footer",
    meta: footerdrmFjXTUSRMeta || {},
    alias: footerdrmFjXTUSRMeta?.alias || [],
    redirect: footerdrmFjXTUSRMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/settings/footer.vue").then(m => m.default || m)
  },
  {
    name: indexjZuWh0s663Meta?.name ?? "settings",
    path: indexjZuWh0s663Meta?.path ?? "",
    meta: indexjZuWh0s663Meta || {},
    alias: indexjZuWh0s663Meta?.alias || [],
    redirect: indexjZuWh0s663Meta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/settings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexsTiQiaYGmpMeta?.name ?? "signup",
    path: indexsTiQiaYGmpMeta?.path ?? "/signup",
    meta: indexsTiQiaYGmpMeta || {},
    alias: indexsTiQiaYGmpMeta?.alias || [],
    redirect: indexsTiQiaYGmpMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: successOwaq1Q43PtMeta?.name ?? "signup-success",
    path: successOwaq1Q43PtMeta?.path ?? "/signup/success",
    meta: successOwaq1Q43PtMeta || {},
    alias: successOwaq1Q43PtMeta?.alias || [],
    redirect: successOwaq1Q43PtMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/signup/success.vue").then(m => m.default || m)
  },
  {
    name: switchn38JyRw5LxMeta?.name ?? "switch",
    path: switchn38JyRw5LxMeta?.path ?? "/switch",
    meta: switchn38JyRw5LxMeta || {},
    alias: switchn38JyRw5LxMeta?.alias || [],
    redirect: switchn38JyRw5LxMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/switch.vue").then(m => m.default || m)
  },
  {
    name: authkaSMnQT4WcMeta?.name ?? "test-auth",
    path: authkaSMnQT4WcMeta?.path ?? "/test/auth",
    meta: authkaSMnQT4WcMeta || {},
    alias: authkaSMnQT4WcMeta?.alias || [],
    redirect: authkaSMnQT4WcMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/auth.vue").then(m => m.default || m)
  },
  {
    name: dupeI47ZtOXqSqMeta?.name ?? "test-dupe",
    path: dupeI47ZtOXqSqMeta?.path ?? "/test/dupe",
    meta: dupeI47ZtOXqSqMeta || {},
    alias: dupeI47ZtOXqSqMeta?.alias || [],
    redirect: dupeI47ZtOXqSqMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/dupe.vue").then(m => m.default || m)
  },
  {
    name: formkm7kcPhdf0Meta?.name ?? "test-form",
    path: formkm7kcPhdf0Meta?.path ?? "/test/form",
    meta: formkm7kcPhdf0Meta || {},
    alias: formkm7kcPhdf0Meta?.alias || [],
    redirect: formkm7kcPhdf0Meta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/form.vue").then(m => m.default || m)
  },
  {
    name: server_45errorzkZTzpIMvfMeta?.name ?? "test-server-error",
    path: server_45errorzkZTzpIMvfMeta?.path ?? "/test/server-error",
    meta: server_45errorzkZTzpIMvfMeta || {},
    alias: server_45errorzkZTzpIMvfMeta?.alias || [],
    redirect: server_45errorzkZTzpIMvfMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/server-error.vue").then(m => m.default || m)
  },
  {
    name: step_45form_452zOUvlFOLgwMeta?.name ?? "test-step-form-2",
    path: step_45form_452zOUvlFOLgwMeta?.path ?? "/test/step-form-2",
    meta: step_45form_452zOUvlFOLgwMeta || {},
    alias: step_45form_452zOUvlFOLgwMeta?.alias || [],
    redirect: step_45form_452zOUvlFOLgwMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/step-form-2.vue").then(m => m.default || m)
  },
  {
    name: step_45formbVuEsOyEdOMeta?.name ?? "test-step-form",
    path: step_45formbVuEsOyEdOMeta?.path ?? "/test/step-form",
    meta: step_45formbVuEsOyEdOMeta || {},
    alias: step_45formbVuEsOyEdOMeta?.alias || [],
    redirect: step_45formbVuEsOyEdOMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/step-form.vue").then(m => m.default || m)
  },
  {
    name: toasterdGo68mWjbTMeta?.name ?? "test-toaster",
    path: toasterdGo68mWjbTMeta?.path ?? "/test/toaster",
    meta: toasterdGo68mWjbTMeta || {},
    alias: toasterdGo68mWjbTMeta?.alias || [],
    redirect: toasterdGo68mWjbTMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/toaster.vue").then(m => m.default || m)
  },
  {
    name: user_45storeNZaKEdPvEJMeta?.name ?? "test-user-store",
    path: user_45storeNZaKEdPvEJMeta?.path ?? "/test/user-store",
    meta: user_45storeNZaKEdPvEJMeta || {},
    alias: user_45storeNZaKEdPvEJMeta?.alias || [],
    redirect: user_45storeNZaKEdPvEJMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/user-store.vue").then(m => m.default || m)
  },
  {
    name: usermbs7HBNeeAMeta?.name ?? "test-user",
    path: usermbs7HBNeeAMeta?.path ?? "/test/user",
    meta: usermbs7HBNeeAMeta || {},
    alias: usermbs7HBNeeAMeta?.alias || [],
    redirect: usermbs7HBNeeAMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/user.vue").then(m => m.default || m)
  },
  {
    name: uitloggenJpGYx4npziMeta?.name ?? "uitloggen",
    path: uitloggenJpGYx4npziMeta?.path ?? "/uitloggen",
    meta: uitloggenJpGYx4npziMeta || {},
    alias: uitloggenJpGYx4npziMeta?.alias || [],
    redirect: uitloggenJpGYx4npziMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/uitloggen.vue").then(m => m.default || m)
  },
  {
    name: add_role1rGOgsOd7sMeta?.name ?? "users-id-add_role",
    path: add_role1rGOgsOd7sMeta?.path ?? "/users/:id()/add_role",
    meta: add_role1rGOgsOd7sMeta || {},
    alias: add_role1rGOgsOd7sMeta?.alias || [],
    redirect: add_role1rGOgsOd7sMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/[id]/add_role.vue").then(m => m.default || m)
  },
  {
    name: edit2D4CWW5dzWMeta?.name ?? "users-id-edit",
    path: edit2D4CWW5dzWMeta?.path ?? "/users/:id()/edit",
    meta: edit2D4CWW5dzWMeta || {},
    alias: edit2D4CWW5dzWMeta?.alias || [],
    redirect: edit2D4CWW5dzWMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexfosjwbxWtaMeta?.name ?? "users-id",
    path: indexfosjwbxWtaMeta?.path ?? "/users/:id()",
    meta: indexfosjwbxWtaMeta || {},
    alias: indexfosjwbxWtaMeta?.alias || [],
    redirect: indexfosjwbxWtaMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: adds9wtR2FJuZMeta?.name ?? "users-add",
    path: adds9wtR2FJuZMeta?.path ?? "/users/add",
    meta: adds9wtR2FJuZMeta || {},
    alias: adds9wtR2FJuZMeta?.alias || [],
    redirect: adds9wtR2FJuZMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/add.vue").then(m => m.default || m)
  },
  {
    name: indexdlFaUjo5BDMeta?.name ?? "users",
    path: indexdlFaUjo5BDMeta?.path ?? "/users",
    meta: indexdlFaUjo5BDMeta || {},
    alias: indexdlFaUjo5BDMeta?.alias || [],
    redirect: indexdlFaUjo5BDMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: editLjOfR8W4PVMeta?.name ?? "vacancies-id-edit",
    path: editLjOfR8W4PVMeta?.path ?? "/vacancies/:id()/edit",
    meta: editLjOfR8W4PVMeta || {},
    alias: editLjOfR8W4PVMeta?.alias || [],
    redirect: editLjOfR8W4PVMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/vacancies/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexVRhjNSCG7qMeta?.name ?? "vacancies-id",
    path: indexVRhjNSCG7qMeta?.path ?? "/vacancies/:id()",
    meta: indexVRhjNSCG7qMeta || {},
    alias: indexVRhjNSCG7qMeta?.alias || [],
    redirect: indexVRhjNSCG7qMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/vacancies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: addrZKEE65rncMeta?.name ?? "vacancies-add",
    path: addrZKEE65rncMeta?.path ?? "/vacancies/add",
    meta: addrZKEE65rncMeta || {},
    alias: addrZKEE65rncMeta?.alias || [],
    redirect: addrZKEE65rncMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/vacancies/add.vue").then(m => m.default || m)
  },
  {
    name: indexlBmD0PXZ6hMeta?.name ?? "vacancies",
    path: indexlBmD0PXZ6hMeta?.path ?? "/vacancies",
    meta: indexlBmD0PXZ6hMeta || {},
    alias: indexlBmD0PXZ6hMeta?.alias || [],
    redirect: indexlBmD0PXZ6hMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/vacancies/index.vue").then(m => m.default || m)
  },
  {
    name: indexF6e2XAjFIcMeta?.name ?? "vacancies-search",
    path: indexF6e2XAjFIcMeta?.path ?? "/vacancies/search",
    meta: indexF6e2XAjFIcMeta || {},
    alias: indexF6e2XAjFIcMeta?.alias || [],
    redirect: indexF6e2XAjFIcMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/vacancies/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexfPOpclTk8DMeta?.name ?? "verschilmakers",
    path: indexfPOpclTk8DMeta?.path ?? "/verschilmakers",
    meta: indexfPOpclTk8DMeta || {},
    alias: indexfPOpclTk8DMeta?.alias || [],
    redirect: indexfPOpclTk8DMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/verschilmakers/index.vue").then(m => m.default || m)
  },
  {
    name: changelog64EQ8JmpEUMeta?.name ?? "volunteerly-changelog",
    path: changelog64EQ8JmpEUMeta?.path ?? "/volunteerly/changelog",
    meta: changelog64EQ8JmpEUMeta || {},
    alias: changelog64EQ8JmpEUMeta?.alias || [],
    redirect: changelog64EQ8JmpEUMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/volunteerly/changelog.vue").then(m => m.default || m)
  },
  {
    name: workshopsZ0xK4fIBTGMeta?.name ?? "workshops",
    path: workshopsZ0xK4fIBTGMeta?.path ?? "/workshops",
    meta: workshopsZ0xK4fIBTGMeta || {},
    alias: workshopsZ0xK4fIBTGMeta?.alias || [],
    redirect: workshopsZ0xK4fIBTGMeta?.redirect,
    component: () => import("/vercel/path0/volunteerly/pages/workshops.vue").then(m => m.default || m)
  },
  {
    name: wrong_45deskSKshfXIVEMMeta?.name ?? "wrong-desk",
    path: wrong_45deskSKshfXIVEMMeta?.path ?? "/wrong-desk",
    meta: wrong_45deskSKshfXIVEMMeta || {},
    alias: wrong_45deskSKshfXIVEMMeta?.alias || [],
    redirect: wrong_45deskSKshfXIVEMMeta?.redirect,
    component: () => import("/vercel/path0/meedoen/pages/wrong-desk.vue").then(m => m.default || m)
  }
]