export interface Desk {
  id: id
  slug: string
  azcName: string
  address: Address
  volunteerCenterId: id
}

export async function fetchDesks() {
  const {
    attributes: desks,
    pending,
    refresh,
    data,
  } = await useApiFetch<Desk[], { addresses: Address[] }>(
    '/api/v1/meedoen/desks?include=address',
  )

  // Map the address to the desk. We should do this through a util. This is too much boilerplate.
  const mapped = computed(() => {
    if (!desks.value) {
      return []
    }
    return desks.value.map((desk) => {
      const deskData = data.value?.data.find(d => d.id == desk.id)
      const addressId = deskData?.relationships?.address?.data?.id
      // Typing wants it like this
      // const address = data.value?.included?.addresses.find(
      //   (i) => i.id == addressId,
      // )
      // While the object is actually like this:
      // (the `as unknown as any[]` is to silence the type error)
      const address = (data.value?.included as unknown as any[])?.find(
        i => i.type == 'address' && i.id == addressId,
      )?.attributes as Address

      return { ...desk, address }
    })
  })

  return { desks: mapped, pending, refresh }
}
