// For all requests that are nested under /organization, we want the user type to be set to 'organization'.
// For all requests that are nested under a specific desk, we want the user type to be set to 'newcomer'.
export default defineNuxtRouteMiddleware((to) => {
  const { setVisitorType } = useVisitorType()

  // When setting a specific desk (see set-desk.ts)
  // const deskSlug = to.params.balie
  // if (deskSlug) {
  //   console.log(`[set-visitor-type] Setting visitor type to newcomer`)
  //   setVisitorType('newcomer')
  // }

  // When visiting an organization page
  // const { organizationsPath } = useAppConfig()
  console.log(`to.path`, to.path)
  if (to.path.includes('/nieuwkomer')) {
    console.log(`[set-visitor-type] Setting visitor type to newcomer`)
    setVisitorType('newcomer')
  }
  else {
    console.log(`[set-visitor-type] Setting visitor type to organization`)
    setVisitorType('organization')
  }
})
