export const useVisitorType = () => {
  const visitorType = useStatefulCookie<
    'newcomer' | 'organization' | undefined
  >('visitor-type', {
    secure: true,
  })

  const setVisitorType = (type: 'newcomer' | 'organization') => {
    visitorType.value = type
  }

  return { visitorType, setVisitorType }
}
