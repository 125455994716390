// Global middleware that set's the currentDesk and currentCenterId based on user state & current route.
// It also redirects to the /wrong-desk page if the user is signed in, and tries to visit a desk page that
// doesn't match the user's own desk, to avoid issues.
export default defineNuxtRouteMiddleware(async (to) => {
  const { currentDesk } = useCurrentDesk()
  const { desks } = await fetchDesks()

  // When a user is logged in, that takes preference over the route param.
  const { currentUser } = storeToRefs(useCurrentUserStore())
  const { currentOrganization } = storeToRefs(useCurrentOrganizationStore())
  const userDesk: Desk | undefined = currentUser.value
    ? desks.value.find(
      d => d.volunteerCenterId == currentOrganization.value.id,
    )
    : undefined

  // Find the first desk slug that matches the route.
  // This is not the cleanest solution, but it works.
  // (as long as all desk pages are nested inside the [balie] folder.)
  let deskFoundInRoute = false

  desks.value.find((desk) => {
    const deskSlug = desk.slug
    if (to.path.startsWith(`/${deskSlug}`)) {
      const desk = desks.value.find(desk => desk.slug === deskSlug)
      if (!desk) {
        return abortNavigation(`Desk with slug ${deskSlug} not found`)
      }
      // Set the desk
      currentDesk.value = desk
      deskFoundInRoute = true
      return true
    }
  })

  // If no desk is found on the user or the route, we'll set the desk to the user's desk, or undefined.
  if (!deskFoundInRoute) {
    currentDesk.value = userDesk || undefined
  }

  // Redirect to the /wrong-desk page if the user's is "visiting another desk",
  // meaning when the user's desk doesn't match the route's desk.
  // I'm not realy happy with this solution, but for an MVP it'll do.
  if (
    currentDesk.value
    && userDesk
    && userDesk.slug !== currentDesk.value?.slug
  ) {
    console.log(`[set-desk] ❌ user is visiting another desk`)
    return navigateTo('/wrong-desk')
  }

  console.log(`[set-desk] 💁‍♂️ currentDesk`, currentDesk.value?.azcName)

  // Lastly, make sure the current center is set to the same center as the desk
  // This is a quickfix. It might make more sense to use this composable in the useCurrentCenterId composable instead.
  const currentCenterId = useCurrentCenterId()
  if (currentDesk.value) {
    currentCenterId.value = currentDesk.value.volunteerCenterId
  }
})
