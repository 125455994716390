/**
 * Simplified version of useCurrentCenterId that doesn't rely on async cookies for keeping state.
 * This is necessary because for meedoen, the center changes all the time.
 */
export const useCurrentCenterId = () => {
  const publicConfig = useRuntimeConfig().public
  const initialVolunteerCenterId = publicConfig.defaultVolunteerCenterId
  if (initialVolunteerCenterId === undefined) {
    // TODO: Report to Sentry
    console.error(
      `volunteerCenterId is not set. Make sure the NUXT_VOLUNTEER_CENTER_ID config is set.`,
    )
  }

  return useState<id>(
    'volunteerCenterId',
    () => initialVolunteerCenterId,
  )
}
