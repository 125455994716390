export const useCurrentOrganizationStore = defineStore(
  'currentOrganization',
  () => {
    const { captureException } = useSentry()

    // State
    const userOrganizations = ref<Organization[]>([])
    // The selected organization, but not guaranteed to be set.
    const selectedOrganization = ref<Organization | undefined>(undefined)
    // A non-null version of `selectedOrganization` for context where we know the user is signed in.
    // This is the cousing of `currentUser` in the currentUserStore.
    const currentOrganization = computed(() => selectedOrganization.value!)
    // Statusses
    const loading = ref(false)
    const initialized = ref(false)
    const failed = ref(false)
    // Error(s)
    // const error = ref<Error | null>(null)

    // Actions
    async function fetch() {
      loading.value = true
      try {
        // TODO: let `fetchUserOrganizations` unwrap the response
        const { data } = await fetchUserOrganizations()
        userOrganizations.value = data.map(resource => resource.attributes)
        // console.log(
        //   `Fetched user organizations`,
        //   userOrganizations.value.length,
        // )
        selectFirstOrganization()
      }
      catch (err: any) {
        console.error(
          '[currentOrganizationStore] Failed to fetch user organizations:',
        )
        captureException(err)
        // This was causing Nuxt crashes. Not exactly sure why, something with error serialization.
        // error.value = err
        failed.value = true
      }
      finally {
        loading.value = false
      }
    }

    async function initialize() {
      if (initialized.value) {
        console.log(`[currentOrganizationStore] Already initialized.`)
        return
      }
      console.log(`[currentOrganizationStore] Initializing...`)
      await fetch()
      initialized.value = !failed.value
      console.log(`[currentOrganizationStore] Initialized.`)
    }

    function selectFirstOrganization() {
      if (userOrganizations.value.length) {
        selectedOrganization.value = userOrganizations.value[0]
      }
    }

    // Clear on sign out
    const { user } = storeToRefs(useCurrentUserStore())
    watch(user, (user) => {
      if (!user) {
        console.log(
          `[currentOrganizations] User signed out => clearing organizations`,
        )
        userOrganizations.value = []
        loading.value = false
        failed.value = false
        initialized.value = false
        selectedOrganization.value = undefined
      }
    })

    // Return store API
    return {
      // State
      userOrganizations,
      selectedOrganization,
      currentOrganization, // computed property
      loading,
      initialized,
      failed,
      // Actions
      initialize,
      fetch,
    }
  },
)
