
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "ca7e4db8-3c3f-4bcc-901b-cfd0dfeeb44d"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/meedoen/app.config.ts"
import cfg1 from "/vercel/path0/volunteerly/app.config.ts"
import cfg2 from "/vercel/path0/.app/app.config.ts"
import cfg3 from "/vercel/path0/layers/tairo-layout-sidebar/app.config.ts"
import cfg4 from "/vercel/path0/layers/tairo-layout-collapse/app.config.ts"
import cfg5 from "/vercel/path0/layers/tairo-layout-topnav/app.config.ts"
import cfg6 from "/vercel/path0/layers/tairo/app.config.ts"
import cfg7 from "/vercel/path0/node_modules/.pnpm/@shuriken-ui+nuxt@1.8.0_nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0_y64ni536y2zqo3isegye3wbtxq/node_modules/@shuriken-ui/nuxt/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, cfg6, cfg7, inlineConfig)
