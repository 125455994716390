export const useCurrentUserStore = defineStore('currentUser', () => {
  // State
  const loading = ref(false)
  const failed = ref(false)

  const user = ref<User | null>(null)
  const roles = ref<Role[]>([])

  // Actions
  async function fetch() {
    loading.value = true

    const { user: fetchedUser, relationships, error } = await fetchCurrentUser()

    if (!error.value && fetchedUser.value) {
      console.log(`[currentUserStore] Fetched current user`)
      user.value = fetchedUser.value
      roles.value = relationships.value?.roles || []
      failed.value = false
    }
    else {
      user.value = null
      roles.value = []
      failed.value = true
      console.error('Failed to fetch current user:', error.value)
    }

    loading.value = false
  }

  // Note: not used at the moment. Currently, we're signing out by watching the authStatus.
  async function signOut() {
    const auth = useAuth()
    await auth.signOut()
    // user.value = null
    // roles.value = []
  }

  // Getters
  // `currentUser` is Non-null user, for context where we know the user is signed in. Use this getter
  // when you're sure the user is signed in. This creates a bit of complexity when signing out, because
  // we need to wait for the signout to finish before we clear the user, otherwise we get crashes. However
  // the benefit is that we don't need v-if="currentUser" everywhere.
  const currentUser = computed(() => user.value!)

  // Watching for authStatus for signout
  const { authStatus } = useAuth()
  watch(authStatus, (newStatus) => {
    if (newStatus == AuthStatus.SignedOut) {
      // Wait before clearing the user because we we need to be sure the redirect to home
      // is finished before we clear the user. Otherwise our forced non-null user unwrap
      // will cause crashes. This is not ideal, I know. But other solutions were also complicated.
      setTimeout(() => {
        user.value = null
        roles.value = []
      }, 500) // assuming the redirect to home is done in 500ms
    }
  })

  // Return store API
  return {
    // State
    loading,
    failed,
    user,
    roles,
    currentUser, // computed
    // Actions
    fetch,
    signOut,
  }
})
