export const useDefaultHeaders = () => {
  const centerId = useCurrentCenterId()
  const runtimeConfig = useRuntimeConfig()
  const { selectedOrganization } = storeToRefs(useCurrentOrganizationStore())

  return computed(() => {
    const h = {
      'App-Context': runtimeConfig.public.isMeedoen ? 'meedoen' : 'gelderland',
      'Volunteer-Center-Id': centerId.value as string,
    }

    if (selectedOrganization.value) {
      h['Current-Organization-Id'] = selectedOrganization.value?.id as string
    }

    return h
  })
}
