export default {
  intro: () => import("/vercel/path0/meedoen/layouts/intro.vue").then(m => m.default || m),
  meedoen: () => import("/vercel/path0/meedoen/layouts/meedoen.vue").then(m => m.default || m),
  default: () => import("/vercel/path0/.app/layouts/default.vue").then(m => m.default || m),
  empty: () => import("/vercel/path0/.app/layouts/empty.vue").then(m => m.default || m),
  public: () => import("/vercel/path0/.app/layouts/public.vue").then(m => m.default || m),
  wide: () => import("/vercel/path0/.app/layouts/wide.vue").then(m => m.default || m),
  sidebar: () => import("/vercel/path0/layers/tairo-layout-sidebar/layouts/sidebar.vue").then(m => m.default || m),
  collapse: () => import("/vercel/path0/layers/tairo-layout-collapse/layouts/collapse.vue").then(m => m.default || m),
  topnav: () => import("/vercel/path0/layers/tairo-layout-topnav/layouts/topnav.vue").then(m => m.default || m)
}