import pkg from 'lodash'
const { snakeCase, camelCase } = pkg

export function convertKeysToSnakeCase(object: any): any {
  if (object instanceof FormData) {
    console.log(`Not converting FormData -> assuming it is already snake case`)
    return object
  }
  else if (Array.isArray(object)) {
    return object.map(item => convertKeysToSnakeCase(item))
  }
  else if (typeof object === 'object' && object !== null) {
    return Object.entries(object).reduce((newObject, [key, value]) => {
      return { ...newObject, [snakeCase(key)]: convertKeysToSnakeCase(value) }
    }, {})
  }

  return object
}

export function convertKeysToCamelCase(object: any): any {
  if (Array.isArray(object)) {
    return object.map(item => convertKeysToCamelCase(item))
  }
  else if (typeof object === 'object' && object !== null) {
    return Object.entries(object).reduce((newObject, [key, value]) => {
      return { ...newObject, [camelCase(key)]: convertKeysToCamelCase(value) }
    }, {})
  }

  return object
}
