<script setup lang="ts">
import type { LayoutKey } from '#build/types/layouts'
import type { RouteLocationNormalized } from '#vue-router'

/**
 * Global head configuration
 * @see https://unhead.harlanzw.com/
 * @see https://github.com/unjs/unhead/tree/main
 */

const route = useRoute()

useHead({
  title: route.meta.title || '',
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ikdoemee.nl` : `ikdoemee.nl`
  },
  // link: [
  //   {
  //     rel: 'icon',
  //     type: 'image/png',
  //     href: '/img/favicon.png',
  //   },
  // ],

  // See Tairo demo app for examples
  // meta: [],
})

// See https://masteringnuxt.com/blog/nuxt-3-client-side-error-handling
const recoverFromError = async (error: any) => {
  await navigateTo('/')
  error.value = null
}

// Set layout based on route meta, with fallback to default layout
const router = useRouter()
const defaultLayout = 'meedoen'
const layout = ref<LayoutKey | false | undefined>()
const setLayout = (route: RouteLocationNormalized) => {
  layout.value = (route.meta.layout as LayoutKey | undefined) || defaultLayout
}
setLayout(router.currentRoute.value)
router.afterEach(setLayout)

// Fix for hanging loading indicator
// https://github.com/nuxt/nuxt/issues/25840
const { finish: finishNuxtLoading } = useLoadingIndicator()
finishNuxtLoading()
if (process.client) {
  finishNuxtLoading()
}
</script>

<template>
  <div>
    <NuxtLayout :name="layout">
      <NuxtLoadingIndicator />
      
      
      <NuxtPage />

      
      
      <!-- <NuxtErrorBoundary>
        <NuxtPage />
        <template #error="{ error }">
          <h2>{{ error.statusCode }}</h2>
          <p>Er ging iets mis: {{ error }}</p>
          <button @click="recoverFromError(error)">
            Ga terug naar de homepage
          </button>
          <TairoError :error="error" />
        </template>
      </NuxtErrorBoundary> -->
    </NuxtLayout>
  </div>
</template>
