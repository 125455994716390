import { defu } from 'defu'
import { type NitroFetchRequest } from 'nitropack'

const logsEnabled = false

// Source: https://stackoverflow.com/questions/73354780/nuxt-3-how-to-add-default-parameters-and-headers-to-fetch
// Throws an error on failed requests.
export function $api<
  T = unknown,
  R extends NitroFetchRequest = NitroFetchRequest,
>(
  request: Parameters<typeof $fetch<T, R>>[0],
  options?: Partial<Parameters<typeof $fetch<T, R>>[1]>,
) {
  const config = useRuntimeConfig()
  const defaultHeaders = useDefaultHeaders()
  const { authHeaders, handleAuthResponseHeaders } = useAuth()
  const { addBreadcrumb } = useSentry()

  return $fetch<T, R>(request, {
    baseURL: config.public.railsApiURL,
    ...options,
    onRequest: ({ options }) => {
      options.headers = defu({
        ...defaultHeaders.value,
        ...options.headers,
        ...authHeaders.value,
      })

      // Add a breadcrump for every request
      const hasToken = !!authHeaders.value['access-token']
      addBreadcrumb({
        category: '$api request',
        message: `Has oken: ${hasToken}`,
        level: 'info',
      })

      if (options.body) {
        options.body = convertKeysToSnakeCase(options.body)
      }
    },

    onResponse({ response, options }) {
      if (response._data) {
        // TODO: not tested yet
        response._data = convertKeysToCamelCase(response._data)
      }

      // Note: this has some overlap with useApiFetch
      // I discovered that even though the API shouldn't refresh the token on every request, it
      // still does this for certain requests. Mainly POST requests? I'm not sure.
      if (response.headers && !!response.headers.get('access-token')) {
        // The main issue we were having with the tokens was that the API would return old tokens
        // after signout. There's a fix for that in useApiFetch. The same fix causes issues with $api,
        // due to authentication requests. So we're not using it here.
        //
        // if (import.meta.env.DEV) {
        //   try {
        //     const requestHadAccessToken =
        //       options.headers && (options.headers as any).get('access-token')

        //     if (!requestHadAccessToken) {
        //       console.error(
        //         `[$api] Received a token while the request didn't have one. (Is this a signin request?)`,
        //         requestHadAccessToken,
        //         options,
        //         options.headers,
        //       )
        //     }
        //   } catch (error) {
        //     console.error(error)
        //   }
        // }

        handleAuthResponseHeaders(response.headers)
      }
    },
  })
}
